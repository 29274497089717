import React, { useState } from 'react';
import './Consultation.css';
import { useNavigate } from 'react-router-dom';

const AccordionItem = ({ title, isActive, onClick, children }) => (
    <div className={`accordion-item ${isActive ? 'active' : ''}`}>
        <button className="accordion-header" onClick={onClick}>
            {title}
        </button>
        <div className="accordion-body">
            {children}
        </div>
    </div>
);

const Consultation = () => {
    const [activeTab, setActiveTab] = useState('process');
    const navigate = useNavigate();

    return (
        <div className="consultation-page">
            <div className="header">
                {/* <h1>Consultation</h1>
                <p>Expert guidance for your wellness journey</p> */}
            </div>

            <div className="consultation-card">
                <h2>Naturopathic Consultation</h2>
                <div className="tabs">
                    <button
                        className={`tab-button ${activeTab === 'process' ? 'active' : ''}`}
                        onClick={() => setActiveTab('process')}
                    >
                        Consultation Process
                    </button>
                    <button
                        className={`tab-button ${activeTab === 'approach' ? 'active' : ''}`}
                        onClick={() => setActiveTab('approach')}
                    >
                        Our Approach
                    </button>
                </div>

                <AccordionItem
                    title="Consultation Process"
                    isActive={activeTab === 'process'}
                    onClick={() => setActiveTab('process')}
                >
                    <p>At DM Nature Cure, our consultation process begins with a thorough patient interview, focusing on:</p>
                    <ul>
                        <li>• Identifying the root cause of disease</li>
                        <li>• Reviewing past and present medical history</li>
                        <li>• Conducting a physical examination</li>
                    </ul>
                </AccordionItem>

                <AccordionItem
                    title="Our Approach"
                    isActive={activeTab === 'approach'}
                    onClick={() => setActiveTab('approach')}
                >
                    <p>Our approach is rooted in naturopathy, which believes that the body has an inherent ability to heal itself. We avoid prescribing synthetic drugs, as they can have harmful effects and unwanted side effects. Instead, we help patients gradually taper off medications and integrate conventional medical approaches with naturopathic treatments, including:</p>
                    <ul>
                        <li>• Diet and nutrition counseling</li>
                        <li>• Herbal remedies</li>
                        <li>• Lifestyle modification guidelines</li>
                    </ul>
                    <p>Our holistic approach promotes complete healing, prevents recurrence, and maintains overall wellness throughout one's life. We focus on addressing the underlying causes of disease, rather than just treating symptoms.</p>
                </AccordionItem>
            </div>

            {/* Naturopathic Treatments Section */}
            <div className="naturopathic-treatments-section">
                <h2>NATUROPATHIC TREATMENTS</h2>
                <h5>"Naturopathic Solutions for a Healthier Life"</h5>
                <p>
                    Naturopathic diet and therapies offer a comprehensive approach to wellness, addressing inflammation, nutritional imbalances, and internal toxicity. By adopting naturopathic principles, individuals can:
                </p>
                <ul>
                        <li>• Reduce inflammation and promote healing</li>
                        <li>• Balance nutrition for optimal health</li>
                        <li>• Enhance functional efficiency through internal detoxification</li>
                        <li>• Radiate beauty and vitality through healthy, glowing skin</li>
                </ul>
                <p>
                    In today's fast-paced world, lifestyle factors often contribute to disease. Stress, family and workplace pressures, and competitive demands can lead to:
                </p>
                <ul>
                    <li>• Weakened immune systems</li>
                    <li>• Increased inflammation</li>
                    <li>• Mental health concerns</li>
                </ul>
                <p>
                    Meditation offers a powerful antidote, providing numerous benefits, including:
                </p>
                <ul>
                    <li>• Stress and tension relief</li>
                    <li>• Improved nervous system function</li>
                    <li>• Enhanced memory and creative thinking</li>
                    <li>• Developed problem-solving skills</li>
                    <li>• Promoted emotional wellbeing</li>
                </ul>
                <p>
                    By incorporating naturopathic diet, therapies, and meditation into daily life, individuals can mitigate the negative impacts of modern living and cultivate a healthier, more resilient existence. The elements of nature are used here to treat the ailments.
                </p>
            </div>

            <div className="consultation-options">
                <div className="option online-consultation">
                    <h2>Online Consultation</h2>
                    <p>Connect with our experts online from the comfort of your home.</p>
                    <button onClick={() => navigate("/online-consultation")}>Book Your Slot</button>
                </div>
            </div>
        </div>
    );
};

export default Consultation;
