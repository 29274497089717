import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { X, CalendarClock } from "lucide-react";
import "./Landing.css";
import AboutPreview from "../About_Preview/AboutPreview";
import TherapyList from "../TherapySection/TherapyList";
import LogoCarousel from "../Logo_Carousel/LogoCarousel";
import MemberSlider from "../Member_Slider/MemberSlider";
import GalleryPage from "../GalleryPage/GalleryPage";
import BenefitsOfNaturo from "../BenefitsOfNaturo/BenefitsOfNaturo";
import ResearchSection from "../ResearchSection/ResearchSection";
import ArticleSection from "../ArticleSection/ArticleSection";
import TestimonialSection from "../Testimonials/TestimonialSection";


const carouselItems = [
  {
    backgroundImageUrl: "/homepage/nature-1.jpg",
    description: "Welcome to DM Nature Cure and Wellness Centre, where we harness the power of Naturopathy and Yoga to promote holistic health and wellness."
  },
  {
    backgroundImageUrl: "/homepage/wheelchair.jpg",
    description: "Our treatment approach focuses on preventing and curing acute and chronic diseases through gentle yet effective body detoxification and lifestyle modification."
  },
  {
    backgroundImageUrl:"/homepage/wellness.jpg",
    description: "By addressing the root causes of illness and embracing the interconnectedness of body, mind, and spirit, we empower individuals to achieve optimal health and vitality."
  }
];

const LandingPage = ({ setShowConsultationButton, setShowModal, showModal }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [showBanner, setShowBanner] = useState(false);
  const navigate = useNavigate();

  // useEffect(() => {
  //   const modalShown = sessionStorage.getItem("modalShown");

  //   const timer = setTimeout(() => {
  //     if (!modalShown || modalShown !== "closed") {
  //       setShowBanner(true);
  //       setShowModal(true);
  //       setShowConsultationButton(false);
  //     }
  //   }, 16000); // Show banner after 10 seconds

  //   return () => clearTimeout(timer);
  // }, [setShowModal, setShowConsultationButton]);

  useEffect(() => {
    const autoSlide = setInterval(() => {
      setCurrentSlide((prev) => (prev + 1) % carouselItems.length);
    }, 5000); 

    return () => clearInterval(autoSlide); 
  }, []);

  const handleDownloadClick = () => {
    setShowModal(false);
    setShowConsultationButton(true);
    sessionStorage.setItem("modalShown", "closed");
    navigate("/online-consultation");
  };

  const closeModal = () => {
    setShowModal(false);
    setShowConsultationButton(true);
    sessionStorage.setItem("modalShown", "closed");
  };

  const bannerUrl = "/homepage/banner.jpeg";
  const bannerStyle = {
    backgroundImage: `url(${bannerUrl})`,
    borderRadius: "10px",
  };

  return (
    <div>
      {/* {showModal && showBanner && (
        <div className="fixed inset-0 bg-black bg-opacity-10 backdrop-blur-sm z-50 flex justify-center items-center">
          <div className="banner mt-10 flex flex-col gap-5 text-white" style={bannerStyle}>
            
            <button onClick={closeModal} className="place-self-end">
              <X size={30} />
            </button>
            <div className="overall-btn-container">
              <div className="book-btn-container rounded-xl">
                <button type="button" onClick={handleDownloadClick} className="book-btn bg-black text-white rounded-md">
                  <CalendarClock />
                  Book Your Slot
                </button>
              </div>
            </div>
          </div>
        </div>
      )} */}

      <div className="carousel">
                <div
                    className="carousel-slide"
                    style={{ backgroundImage: `url(${carouselItems[currentSlide].backgroundImageUrl})` }}
                >
                    <div className="carousel-content" key={currentSlide}>
                        <div className="heading-container">
                            <h2 className="heading-1">DM Nature Cure</h2>
                            <p className="and-symbol">&</p>
                            <h2 className="heading-2">Wellness Centre</h2>
                        </div>
                        <p className="description">{carouselItems[currentSlide].description}</p>
                    </div>
                </div>
      </div>

      <AboutPreview />
      <TherapyList />
      <BenefitsOfNaturo />
      <LogoCarousel />
      <MemberSlider />
      <GalleryPage />
      <TestimonialSection />
      <ResearchSection />
      <ArticleSection />
    </div>
  );
};

export default LandingPage;
