
import React from 'react';
import { useParams } from 'react-router-dom';
import './NaturopathyTherapy.css';

const therapyDetails = {
  ozone: {
    title: 'Ozone Therapy',
    description: 'Detailed description of Ozone Therapy...',
    imageUrl: 'https://example.com/ozone.jpg',
  },
  detox: {
    title: 'Detoxification Therapy',
    description: 'Detailed description of Detoxification Therapy...',
    imageUrl: 'https://example.com/detox.jpg',
  },
  rejuvenation: {
    title: 'Rejuvenation Therapy',
    description: 'Detailed description of Rejuvenation Therapy...',
    imageUrl: 'https://example.com/rejuvenation.jpg',
  },
  cupping: {
    title: 'Cupping Therapy',
    description: 'Detailed description of Cupping Therapy...',
    imageUrl: 'https://example.com/cupping.jpg',
  },
  acupuncture: {
    title: 'Acupuncture Therapy',
    description: 'Detailed description of Acupuncture Therapy...',
    imageUrl: 'https://example.com/acupuncture.jpg',
  },
  diet: {
    title: 'Diet Therapy',
    description: 'Detailed description of Diet Therapy...',
    imageUrl: 'https://example.com/diet.jpg',
  },
};

const NaturopathyTherapy = () => {
  const { therapyId } = useParams();
  const therapy = therapyDetails[therapyId];

  if (!therapy) {
    return <div>Therapy not found. Please check the URL and try again.</div>;
  }

  return (
    <div className="naturopathy-therapy-container">
      <h1>{therapy.title}</h1>
      <img src={therapy.imageUrl} alt={therapy.title} className="therapy-image" />
      <p>{therapy.description}</p>
    </div>
  );
};

export default NaturopathyTherapy;