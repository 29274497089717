import React, { useEffect, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './About.css';

const About = () => {
  const [activeTab, setActiveTab] = useState('aim');
  const backgroundImageUrl = 'https://example.com/your-background-image.jpg'; 
  const carouselImages = [
    'https://i.ibb.co/hRGgVMN/about-us.jpg',
    'https://i.ibb.co/YTw1mJJ/accupunture.jpg',
    'https://i.ibb.co/WgcjT91/yoga.jpg',
  ];

  const tabContent = {
    aim: [
      'Empowering individuals with complete healing through natural modalities.',
      'Prioritizing on long-term wellness rather than just symptomatic relief and prevent complications.',
      'Fostering sustainable health by guiding our clients towards transformative dietary habits and lifestyle modifications.',
      
    ],
    vision: [
      '• Our vision is to empower individuals with awareness and education on holistic, disease-specific treatment regimens, harnessing the synergistic power of interconnected systems: Naturopathy, Yoga, and Diet Therapy. ',
      '• We aim to provide the most effective and sustainable solutions for achieving and maintaining optimal health and wellness.',
    ],
    mission: [
      ' Our mission is to promote holistic health care through Naturopathy, Yoga therapy, Diet therapy, and other drugless systems of medicine, dedicated to benefiting humanity and empowering individuals to achieve optimal wellness.'
    ]
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="about-us-container">
      <div
        className="about-us-background"
        style={{ backgroundImage: `url(${backgroundImageUrl})` }}
      >
        <div className="about-us-content">
          <div className="carousel-container">
            {/* <Carousel
              autoPlay
              infiniteLoop
              showThumbs={false}
              showStatus={false}
              interval={3000}
              transitionTime={600}
            >
              {carouselImages.map((url, index) => (
                <div key={index}>
                  <img src={url} alt={`Carousel Image ${index + 1}`} />
                </div>
              ))}
            </Carousel> */}
            <img src="https://i.ibb.co/hRGgVMN/about-us.jpg" alt="about-us"/>
            <div className="about-buttons">
              <button
                className={activeTab === 'aim' ? 'active' : ''}
                onClick={() => setActiveTab('aim')}
              >
                Aim
              </button>
              <button
                className={activeTab === 'vision' ? 'active' : ''}
                onClick={() => setActiveTab('vision')}
              >
                Vision
              </button>
              <button
                className={activeTab === 'mission' ? 'active' : ''}
                onClick={() => setActiveTab('mission')}
              >
                Mission
              </button>
            </div>
            <div className={`tab-content ${activeTab}`}>
              <h2>{activeTab.charAt(0).toUpperCase() + activeTab.slice(1)}</h2>
              {activeTab === 'aim' ? (
                <ul>
                  {tabContent.aim.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
                

              ) : (
                tabContent[activeTab].map((item, index) => <p key={index}>{item}</p>)
              )}
            </div>


           
          </div>
          <div className="about-us-text">
            <h1>About Us</h1>
            <p>
        DM Nature Cure, founded by Dr. M. Dhanalakshmi on May 11th, 2023,
        in Karaikal, Puducherry, India, is a pioneering complementary and
        preventive medical center. Our mission is to restore and rebuild 
        individual health, fostering a healthier society.
        </p>
        <p>We offer a comprehensive range of services, including: </p>
        <div className='ul-l'>
        <ul className='list-1'>
          <li><i className="fa-solid fa-arrow-right"></i> Naturopathy</li>
          <li><i className="fa-solid fa-arrow-right"></i> Ozone Therapy</li>
          <li><i className="fa-solid fa-arrow-right"></i> Detoxification Therapy</li>
          <li><i className="fa-solid fa-arrow-right"></i> Rejuvenation Therapy</li>
          <li><i className="fa-solid fa-arrow-right"></i> Cupping Therapy</li>
          <li><i className="fa-solid fa-arrow-right"></i> Acupuncture Therapy</li>
          <li><i className="fa-solid fa-arrow-right"></i> Diet Therapy</li>
          <li><i className="fa-solid fa-arrow-right"></i> Mud Therapy</li>
         
        </ul>
        <ul className='list-2'>
        <li><i className="fa-solid fa-arrow-right"></i>Therapeutic Yoga</li>
          <li><i className="fa-solid fa-arrow-right"></i> Underwater Massage</li>
          <li><i className="fa-solid fa-arrow-right"></i> Therapeutic Massage</li>
          <li><i className='fa-solid fa-arrow-right'></i> Pain Management</li>
          <li><i className="fa-solid fa-arrow-right"></i> Stress Management</li>
          <li><i className="fa-solid fa-arrow-right"></i> Anti-Aging</li>
          <li><i className="fa-solid fa-arrow-right"></i> Meditation </li>
          <li><i className="fa-solid fa-arrow-right"></i> Lifestyle Modification</li>
        </ul>
        </div>
        <br></br>
        <p>
        Guided by the ancient wisdom of the five natural elements -
        Earth, Water, Air, Fire, and Ether - we adopt a holistic approach,
        treating the individual as a whole. Our naturopathic practice empowers
        individuals to achieve optimal wellness by stimulating the body's
        inherent healing forces, trusting in its ability to self-heal and 
        overcome physical and mental ailments.
        </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
