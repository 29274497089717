import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Plus, Minus } from 'lucide-react';
import "./SectionPage.css";

const images = {
  "Our-Clinic": [
    "/gallery/our-clinic/clinic-1.jpeg",
    "/gallery/our-clinic/clinic-2.jpeg",
    "/gallery/our-clinic/clinic-3.jpeg",
    "/gallery/our-clinic/clinic-4.jpeg",
    "/gallery/our-clinic/clinic-5.jpeg",
    "/gallery/our-clinic/clinic-6.jpeg",
    "/gallery/our-clinic/clinic-7.jpeg",
    "/gallery/our-clinic/clinic-8.jpeg",
  ],
  "Indian-Coast-Guard": [
    "/gallery/indian-coast-guard/c-6.jpeg",
    "/gallery/indian-coast-guard/c-5.jpeg",
    "/gallery/indian-coast-guard/c-1.jpeg",
    "/gallery/indian-coast-guard/c-2.jpeg",
    "/gallery/indian-coast-guard/c-4.jpeg",
    "/gallery/indian-coast-guard/c-3.jpeg",
    "/gallery/indian-coast-guard/c-9.jpeg",
    "/gallery/indian-coast-guard/c-7.jpeg",
    "/gallery/indian-coast-guard/c-8.jpeg",
    "/gallery/indian-coast-guard/c-10.jpeg",
    "/gallery/indian-coast-guard/c-11.jpeg",
  ],
  ONGC: [
   
    // "https://i.ibb.co/wQZcRDf/ongc-3.jpg",
    // "https://i.ibb.co/gjSZY6Z/ongc-2.jpg",
    "/gallery/ongc/ongc-1.jpeg", 
    "/gallery/ongc/ongc-4.jpeg",
    "/gallery/ongc/ongc-6.jpeg",
    "/gallery/ongc/ongc-5.jpeg",
    
  ],
  GAIL: [
    "/gallery/gail/gail-1.jpeg",
    "/gallery/gail/gail-2.jpeg",
    // "https://i.ibb.co/rFFfpSn/gail-3.jpg",
    "/gallery/gail/gail-5.jpeg",
    "/gallery/gail/gail-7.jpeg",
    "/gallery/gail/gail-4.jpeg",
    "/gallery/gail/gail-6.jpeg",
  ],
  PAJANCO: [
    "/gallery/pajanco/pa-2.jpeg",
    "/gallery/pajanco/pa-1.jpeg",
    "/gallery/pajanco/pa-3.jpeg",
    "/gallery/pajanco/pa-4.jpeg",
    "/gallery/pajanco/pa-5.jpeg",
    "/gallery/pajanco/pa-6.jpeg",
  ],
  "Women-Polytechnic-College": [
    "/gallery/polytechnic/p-1.jpeg",
    "/gallery/polytechnic/p-2.jpeg",
    "/gallery/polytechnic/p-3.jpeg",
    "/gallery/polytechnic/p-4.jpeg",
    "/gallery/polytechnic/p-5.jpeg",
    "/gallery/polytechnic/p-6.jpeg",
    "/gallery/polytechnic/P7.jpeg",
    "/gallery/polytechnic/P8.jpeg",
    "/gallery/polytechnic/P9.jpeg",
    "/gallery/polytechnic/P10.jpeg",
    "/gallery/polytechnic/P11.jpeg",
  ],
  'Other': [
    //"https://i.ibb.co/s9ytnmL/other-1.jpg",
    //"https://i.ibb.co/4NRD2k4/other-2.jpg",
    // Add more images as needed
  ]
};

const sections = [
  "Our-Clinic",
  "Indian-Coast-Guard",
  "ONGC",
  "GAIL",
  "PAJANCO",
  "Women-Polytechnic-College",
  "Other",
];

const otherSections = [
  {
    name: "Nirmala Rani Girls Hr Sec School",
    path: "nirmala-rani-girls-hr-sec-school",
    img: [
      "/gallery/nirmala/nirmala-school-11.jpeg",
      "/gallery/nirmala/nirmala-school-22.jpeg",
    ],
    count: 4,
  },
  {
    name: "The Bright Academy",
    path: "the-bright-academy",
    img: [
      "/gallery/bright/bright-academy-1.jpg",
      "/gallery/bright/bright-academy-2.jpg",
    ],
    count: 3,
  },
  {
    name: "Rotary Club",
    path: "rotary-club",
    img: [
      "/gallery/rotary/rotary-club-1.jpg",
      "/gallery/rotary/rotary-club-2.jpg",
    ],
    count: 5,
  },
  {
    name: "Govt Hr Sec School",
    path: "govt-hr-sec-school",
    img: [
      "/gallery/govt-school/gh-school-1.jpg",
      "/gallery/govt-school/gh-school-2.jpg",
    ],
    count: 5,
  },
];
//major updates
const SectionPage = () => {
  const { section } = useParams();
  const navigate = useNavigate();
  const [accordionOpen, setAccordionOpen] = useState(false);

  useEffect(() => {
    if (
      section === "Other" ||
      section.startsWith("nirmala-rani-girls-hr-sec-school") ||
      section.startsWith("the-bright-academy") ||
      section.startsWith("rotary-club") ||
      section.startsWith("govt-hr-sec-school")
    ) {
      setAccordionOpen(true);
    } else {
      setAccordionOpen(false);
    }
  }, [section]);

  const handleOtherClick = () => {
    setAccordionOpen(!accordionOpen);
  };

  const handleSubSectionClick = (path) => {
    navigate(`/${path}`);
  };

  const handleMainSectionClick = (sec) => {
    navigate(`/${sec}`);
    setAccordionOpen(false);
  };

  return (
    <div className="section-container">
      <div className="sidebar">
        {Object.keys(images).map((sec) => (
          <div key={sec}>
            {sec === "Other" ? (
              <>
                <button
                  onClick={handleOtherClick}
                  className={`sidebar-button ${accordionOpen ? "active" : ""}`}
                >
                  See More
                  {accordionOpen ? (
                    <Minus className="accordion-icon" />
                  ) : (
                    <Plus className="accordion-icon" />
                  )}
                </button>
                <div
                  className={`accordion-content ${accordionOpen ? "open" : ""}`}
                >
                  {otherSections.map((sub) => (
                    <button
                      key={sub.name}
                      className={`sidebar-sub-link ${
                        section === sub.path ? "active-sub-link" : ""
                      }`}
                      onClick={() => handleSubSectionClick(sub.path)}
                    >
                      {sub.name}
                    </button>
                  ))}
                </div>
              </>
            ) : (
              <Link
                to={`/${sec}`}
                className={`sidebar-link ${section === sec ? "active" : ""}`}
                onClick={() => handleMainSectionClick(sec)}
              >
                {sec.replaceAll("-", " ")}
              </Link>
            )}
          </div>
        ))}
      </div>
      <div className="images-container">
        {section.startsWith("nirmala-rani-girls-hr-sec-school") ||
        section.startsWith("the-bright-academy") ||
        section.startsWith("rotary-club") ||
        section.startsWith("govt-hr-sec-school") ? (
          otherSections
            .filter((sub) => sub.path === section)
            .flatMap((sub) =>
              sub.img.map((img, index) => (
                <div key={index} className="image-card">
                  <img src={img} alt={sub.name} className="gallery-image" />
                </div>
              ))
            )
        ) : (
          images[section]?.map((img, index) => (
            <div key={index} className="image-card">
              <img src={img} alt={`Gallery Image ${index}`} className="gallery-image" />
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default SectionPage;