import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './TestimonialSection.css';

import { Autoplay, Pagination, Navigation } from 'swiper/modules';

const testimonials = [
  {
    name: 'Rajendran',
    date: 'Buraidah,Saudi Arabia.',
    text: ' "My blood sugar level (PPBS )with insulin was 412mg/dl and my doctor refused to go for cataract surgery. Later after following the recommended diet given in DM centre for 2 weeks with insulin  my (PPBS) fell down to 213 and they performed surgery on both of my eyes. I was very thankful and very much relieved with my plan of treatment." ',
    image: 'https://i.ibb.co/TKKQbpT/userimg.png',
  },
  {
    name: 'Almaisha',
    date: 'Needamangalam, Tamil Nadu, India',
    text: ' "I lost 5 kgs of weight in a month with dietary modification, even my foot corns have disappeared with Ozone Therapy at DM wellness.I am delighted with my healthy weight loss program and thankful to see changes in my entire complexion." ',
    image: 'https://i.ibb.co/TKKQbpT/userimg.png',
  },
  {
    name: 'Rajeswari',
    date: 'Karaikal,Pondicherry.',
    text: ' "I suffered with PCOD since 5 yrs with irregular periods and gradual weight gain inspite of medication. I visited DM centre and got my periods without medication and lost my weight effecrively with their diet plan and other measures. I am happy to say that i learnt to lead a healthy way of living....."  ',
    image: 'https://i.ibb.co/TKKQbpT/userimg.png',
  },
  {
    name: 'Amudha',
    date: 'Mannarkudy,Tamilnadu',
    text: ' "I was suffering with  Rheumatoid Arthritis since 10 yrs. Inspite of medication, i had swollen joints and difficulty in mobilizing it. I tried many but with no improvement i left all beside without hope and started following naturopathy from DM centre. To my surprise i was able to live without medicine for pain after 10 yrs for the first time.With all the therapies and follow up i am able to mobilise my joints reduced swelling and pain now, half the condition disapperared in 2 months. I am glad to say that i am able to walk without external support  and medication as well after a long time." ',
    image: 'https://i.ibb.co/TKKQbpT/userimg.png',
  },

];

const TestimonialSection = () => {
  return (
    <div className="testimonial-section">
      <h2>What People Say?</h2>
      <Swiper
        modules={[Autoplay, Pagination, Navigation]}
        spaceBetween={20}
        slidesPerView={2}
        loop={true}
        autoplay={{
          // delay: 3000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true
        }}
        pagination={{ clickable: true }}
        navigation={true}
        breakpoints={{
          320:{
            slidesPerView: 1,
          },
          768: {
            slidesPerView: 1,
          },
          1024: {
            slidesPerView: 2,
          },
        }}
      >
        {testimonials.map((testimonial, index) => (
          <SwiperSlide key={index}>
            <div className="testimonial">
              <img src={testimonial.image} alt={testimonial.name} />
              <div className="testimonial-content">
                <p>{testimonial.text}</p>
                <h3>{testimonial.name}</h3>
                <p className="date">{testimonial.date}</p>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default TestimonialSection;
