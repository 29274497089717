import React from 'react';
import { useNavigate } from 'react-router-dom';
import './TherapyCard.css';
import { FaArrowRight } from 'react-icons/fa'; 

const TherapyCard = ({ title, imageUrl, description, id }) => {
  const navigate = useNavigate();

  const handleCardClick = () => {
    navigate(`/therapies?therapyId=${id}`);
    window.scrollTo(0, 0); 
  };

  return (
    <div className="therapy-card" onClick={handleCardClick}>
      <img src={imageUrl} alt={title} className="therapy-card-image" />
      <div className="therapy-card-content">
        <h2>{title}</h2>
        <div className="therapy-card-link">
          Read More <FaArrowRight className="arrow-icon" />
        </div>
      </div>
    </div>
  );
};

export default TherapyCard;
