import React from 'react';
import TherapyCard from './TherapyCard';
import './TherapyList.css';
import { useNavigate } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';

const therapies = [
  {
    id: 'ozone',
    title: 'Ozone Therapy',
    imageUrl: '/therapies/Ozone-therapy.jpg',
    description: 'From $200 USD',
  },
  {
    id: 'detox',
    title: 'Detoxification Therapy',
    imageUrl: '/therapies/detox.jpg',
    description: 'From $250 USD',
  },
  {
    id: 'rejuvenation',
    title: 'Rejuvenation Therapy',
    imageUrl: '/therapies/rejinuvation.jpg',
    description: 'From $300 USD',
  },
  {
    id: 'cupping',
    title: 'Cupping Therapy',
    imageUrl: 'https://i.ibb.co/c2LLFMQ/cub-therapy.jpg',
    description: 'From $150 USD',
  },
  {
    id: 'acupuncture',
    title: 'Acupuncture Therapy',
    imageUrl: 'https://i.ibb.co/YTw1mJJ/accupunture.jpg',
    description: 'From $180 USD',
  },
  {
    id: 'diet',
    title: 'Diet Therapy',
    imageUrl: '/therapies/diet.jpg',
    description: 'From $220 USD',
  },
];

const TherapyList = () => {
  const navigate = useNavigate();
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const handleReadMoreClick = () => {
    navigate('/therapies');
    window.scrollTo(0, 0); 
  };

  return (
    <div
      ref={ref}
      className={`therapy-list-container ${inView ? 'visible' : ''}`}
    >
      <h2 id='therapies-id'>Popular Therapies</h2>
      <div className="therapy-list">
        {therapies.map((therapy, index) => (
          <TherapyCard key={index} {...therapy} />
        ))}
      </div>
      <div className="read-more-button-container">
        <button className="read-more-button" onClick={handleReadMoreClick}>Read More Therapies</button>
      </div>
    </div>
  );
};

export default TherapyList;
