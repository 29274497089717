import React, { useState, useEffect } from "react";
import axios from "axios";
import './ConsultationBookingForm.css';

const countryCodes = [
  { code: "+61", name: "Australia" },
  { code: "+973", name: "Bahrain" },
  { code: "+33", name: "French"},
  { code: "+49", name: "Germany" },
  { code: "+91", name: "India" },
  { code: "+965", name: "Kuwait" },
  { code: "+968", name: "Oman" },
  { code: "+974", name: "Qatar" },
  { code: "+966", name: "Saudi Arabia" },
  { code: "+971", name: "United Arab Emirates" },
  { code: "+44", name: "United Kingdom" },
  { code: "+1", name: "United States" },
  // Add more countries here...
];

const ConsultationBookingForm = ({ setShowConsultationButton }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    gender: "",
    age: "",
    address: "",
    date: "",
    timeSlot: "",
    countryCode: "+91",  // Default country code
    mobile: "",          // Mobile number without country code
  });
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [userName, setUserName] = useState("");

  useEffect(() => {
    setShowConsultationButton(false);
    return () => {
      setShowConsultationButton(true);
    };
  }, [setShowConsultationButton]);


  // Handle input changes
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Show spinner

    // Combine the country code and mobile number
    const fullMobileNumber = `${formData.countryCode}${formData.mobile}`;

    const finalFormData = {
      ...formData,
      mobile: fullMobileNumber, // Combine country code and mobile number
    };

    console.log("Form data being sent:", finalFormData); // Debugging

    try {
      //harmonious-education.railway.internal
      //await axios.post("http://localhost:8081/api/book-slot", finalFormData);
      await axios.post("https://harmonious-education-production.up.railway.app/api/book-slot", finalFormData);
      setUserName(formData.name);
      setLoading(false); // Hide spinner
      setSuccess(true); // Show success modal
    } catch (error) {
      console.error("Error sending booking confirmation:", error);
      setLoading(false); // Hide spinner
      setError(true); // Show failure modal
    }
  };

  return (
    <div className="form-container">
      <h2>Online Consultation Booking</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-grid">
          <div className="form-left">
            <div className="form-group">
              <label>Name:</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label>Country Code:</label>
              <select
                name="countryCode"
                value={formData.countryCode}
                onChange={handleChange}
              >
                {countryCodes.map((country) => (
                  <option key={country.code} value={country.code}>
                    {country.name} ({country.code})
                  </option>
                ))}
              </select>
            </div>

            <div className="form-group">
              <label>Mobile Number:</label>
              <input
                type="tel"
                name="mobile"
                value={formData.mobile}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label>Email:</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label>Gender:</label>
              <select
                name="gender"
                value={formData.gender}
                onChange={handleChange}
                required
              >
                <option value="">Select Gender</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Other">Other</option>
              </select>
            </div>
          </div>

          <div className="form-right">
            <div className="form-group">
              <label>Age:</label>
              <input
                type="number"
                name="age"
                value={formData.age}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group2">
              <label>Address:</label>
              <textarea
                name="address"
                value={formData.address}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label>Select Date:</label>
              <input
                type="date"
                name="date"
                value={formData.date}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label>Select Time Slot:</label>
              <select
                name="timeSlot"
                value={formData.timeSlot}
                onChange={handleChange}
                required
              >
                <option value="">Select Time Slot</option>
                <option value="09:00 AM - 10:00 AM">09:00 AM - 10:00 AM</option>
                <option value="10:00 AM - 11:00 AM">10:00 AM - 11:00 AM</option>
                <option value="11:00 AM - 12:00 PM">11:00 AM - 12:00 PM</option>
                <option value="04:00 PM - 05:00 PM">04:00 PM - 05:00 PM</option>
                <option value="05:00 PM - 06:00 PM">05:00 PM - 06:00 PM</option>
                <option value="06:00 PM - 07:00 PM">06:00 PM - 07:00 PM</option>
                <option value="08:00 PM - 09:00 PM">08:00 PM - 09:00 PM</option>
              </select>
            </div>
          </div>
        </div>

        <div className="button-container">
          <button type="submit" className="submit-button">
            Book Slot
          </button>
        </div>
      </form>

      {/* Spinner Modal */}
      {loading && (
        <div className="modal">
          <div className="spinner-content">
            <div className="loader"></div>
            <p>Please wait while we process your booking...</p>
          </div>
        </div>
      )}

      {/* Success Modal */}
      {success && (
        <div className="modal">
          <div className="success-modal">
            <h2>Thank you, {userName}, for booking your slot!</h2>
            <button onClick={() => window.location.reload()}>Another Booking</button>
            <button onClick={() => window.location.href = "/"}>Home Page</button>
          </div>
        </div>
      )}

      {/* Error Modal */}
      {error && (
        <div className="modal">
          <div className="error-modal">
            <h2>Booking failed. Please try again later.</h2>
            <button onClick={() => setError(false)}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ConsultationBookingForm;
