import React, { useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './GalleryPage.css';
import { ArrowBigRight } from 'lucide-react';

const sections = [
  { name: 'Our Clinic ', 
    count: 8, 
    path: '/Our-Clinic', 
    img: '/gallery/our-clinic/clinic-1.jpeg',
    // logo: 'https://i.ibb.co/TTVh4kX/dm-logo.png',
    logo: 'https://i.ibb.co/7NtRcpR/Whats-App-Image-2024-08-20-at-18-39-36.jpg',
  },
  { name: 'Indian Coast Guard', 
    count: 11, 
    path: '/Indian-Coast-Guard', 
    img: '/gallery/indian-coast-guard/c-1.jpeg',
    logo: 'https://i.ibb.co/B4qdw95/Whats-App-Image-2024-08-21-at-22-41-43.jpg'
  },
  { name: 'ONGC', 
    count: 6, 
    path: '/ONGC', 
    img: '/gallery/ongc/ongc-1.jpeg',
    logo: 'https://i.ibb.co/0VSZkPv/ongc.png'
  },
  { name: 'GAIL', 
    count: 6, 
    path: '/GAIL', 
    img: '/gallery/gail/gail-1.jpeg',
    logo: 'https://i.ibb.co/JmVnnmB/gail-logo.png'
  },
  { name: 'PAJANCO', 
    count: 6, 
    path: '/PAJANCO', 
    img: '/gallery/pajanco/pa-1.jpeg',
    logo: 'https://i.ibb.co/RNjy4H3/panjanco.jpg'
  },
  { name: "Women Polytechnic College", 
    count: 11, 
    path:"/Women-Polytechnic-College", 
    img: "/gallery/polytechnic/p-1.jpeg",
    logo: 'https://i.ibb.co/mFSZswK/polytechnic.jpg'
  },
];

const GalleryPage = () => {
  const sectionRefs = useRef([]);
  const lastScrollTop = useRef(0);
  const navigate = useNavigate();
  
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

      if (scrollTop > lastScrollTop.current) {
        sectionRefs.current.forEach((ref) => {
          if (ref) {
            const rect = ref.getBoundingClientRect();
            if (rect.top < window.innerHeight) {
              ref.classList.add('show');
            }
          }
        });
      }
      lastScrollTop.current = scrollTop <= 0 ? 0 : scrollTop;
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  

  return (
    <div className="gallery-container">
      <h1 className="head">Our's Gallery</h1>

      <div className="gallery-grid">
        {sections.map((section, index) => (
          <Link
            to={section.path}
            key={section.name}
            className={`gallery-section gallery-section-${index + 1}`}
            ref={(el) => (sectionRefs.current[index] = el)}
          >
            <div className="gallery-image-wrapper">
              <img src={section.img} alt={section.name} className="gallery-image" />
              <div className="gallery-overlay">
                <div className="gallery-info">
                  <div className="gallery-info-content">
                    <img className={`logo-${index + 1}`} src={section.logo} alt={`logo-${index + 1}`} />
                    <h2>{section.name}</h2>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        ))}
      </div>

      <div className="see-more-container">
      <Link className="see-more" to="/nirmala-rani-girls-hr-sec-school">
  See More
  <ArrowBigRight className="see-more-icon" />
</Link>

      </div>
    </div>
  );
};

export default GalleryPage;
