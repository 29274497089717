import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import './AboutPreview.css';

const AboutPreview = () => {
  const navigate = useNavigate();
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0,
  });

  const localImage = "/homepage/about-us-preview.png";

  return (
    <div ref={ref} className={`shop-local-container ${inView ? 'visible' : ''}`}>
      <div className="image-container">
        <img src={localImage} alt="About DM Nature Cure" />
      </div>
      <div className="text-container">
        <h1>About Us</h1>
        <p>
          DM Nature Cure, founded by Dr. M. Dhanalakshmi on May 11th, 2023, in Karaikal, Puducherry, India, is a pioneering center for complementary and preventive medicine. Our mission is to restore and rebuild individual health, fostering a healthier society.
        </p>
        <p>
          We offer a comprehensive range of services, including:
        </p>
        <ul>
        <li><i className="fa-solid fa-arrow-right"></i> Naturopathy</li>
          <li><i className="fa-solid fa-arrow-right"></i> Ozone Therapy</li>
          <li><i className="fa-solid fa-arrow-right"></i> Detoxification Therapy</li>
          <li><i className="fa-solid fa-arrow-right"></i> Rejuvenation Therapy</li>
          <li><i className="fa-solid fa-arrow-right"></i> Cupping Therapy</li>
          <li><i className="fa-solid fa-arrow-right"></i> Acupuncture Therapy</li>
          <li><i className="fa-solid fa-arrow-right"></i> Diet Therapy</li>
          <li><i className="fa-solid fa-arrow-right"></i> Mud Therapy</li>
          <li><i className="fa-solid fa-arrow-right"></i> Therapeutic Yoga</li>
          <li><i className="fa-solid fa-arrow-right"></i> Underwater Massage</li>
          <li><i className="fa-solid fa-arrow-right"></i> Therapeutic Massage</li>
          <li><i className="fa-solid fa-arrow-right"></i> Pain Management</li>
          <li><i className="fa-solid fa-arrow-right"></i> Stress Management</li>
          <li><i className="fa-solid fa-arrow-right"></i> Anti-Aging</li>
          <li><i className="fa-solid fa-arrow-right"></i> Meditation</li>
          <li><i className="fa-solid fa-arrow-right"></i> Lifestyle Modification</li>
        </ul>
        <p>
          Guided by the ancient wisdom of the five natural elements — Earth, Water, Air, Fire, and Ether — we adopt a holistic approach, treating individuals as a whole. Our naturopathic practice empowers individuals to achieve optimal wellness by stimulating the body’s inherent healing forces, trusting its ability to self-heal and overcome physical and mental ailments.
        </p>
      
        <div className='button-container'>
          <button className='about-read-button' onClick={() => navigate('/about')}>Read More</button>
        </div>
      </div>
    </div>
  );
};

export default AboutPreview;
