import React from 'react';
import './VideoSection.css';

const VideoSection = () => {
  return (
    <div className="video-section-container">
      <div className="video-section-content">
        <div className="video-section-video">
          <iframe
            src="https://drive.google.com/file/d/1dVpJRT-zgQEjJNynCwqOSuPzOMe4TGyA/preview"
            frameBorder="0"
            allow="autoplay"
            allowFullScreen
            title="Embedded Video"
          ></iframe>
        </div>
        <div className="video-section-text">
          <blockquote className="video-quote">
            <i className="fas fa-quote-left"></i> {/* Font Awesome icon */}
            <p>PRIME MINISTER </p>
            <p>SPEECH ON</p>
            <p>AYUSH MEDICINE</p>
            <i className="fas fa-quote-right"></i>
          </blockquote>
          {/* <p>Additional text content can go here if needed.</p> */}
        </div>
      </div>
    </div>
  );
};

export default VideoSection;