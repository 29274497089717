import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './NewNavbar.css';

const NewNavbar = ({ setMenuOpen }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [showTitle, setShowTitle] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null); // For mobile accordion
  const location = useLocation();
  const navigate = useNavigate();

  const handleMenuToggle = () => {
    const newMenuState = !isMenuOpen;
    setIsMenuOpen(newMenuState);
    setMenuOpen(newMenuState);
    if (!newMenuState) {
      setActiveDropdown(null); // Close all accordions when the menu is closed
    }
  };

  const handleDropdownToggle = (menu) => {
    setActiveDropdown(activeDropdown === menu ? null : menu);
  };

  const handleDropdownClick = (path) => {
    navigate(path);
    setIsMenuOpen(false);
  };

  const handleDropdownClickForTherapies = (path, queryId) => {
    navigate(`${path}?therapyId=${queryId}`);
    setIsMenuOpen(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;

      if (location.pathname === '/') {
        setScrolled(offset > 50);
        setShowTitle(offset > 300);
      } else {
        setScrolled(true);
        setShowTitle(true);
      }
    };

    if (location.pathname !== '/') {
      setScrolled(true);
      setShowTitle(true);
    } else {
      setScrolled(false);
      setShowTitle(false);
    }

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [location]);

  return (
    <nav className={`new-navbar ${scrolled ? 'scrolled' : ''}`}>
      <div className="navbar-content">
        <Link to="/" className="logo">
          <img src="/homepage/dm-logo.png" alt="img" />
        </Link>
        {showTitle && (
          <div className={`navbar-title ${showTitle ? 'visible' : 'hidden'}`}>
            DM Nature Cure
          </div>
        )}
        <div className={`navbar-links ${isMenuOpen ? 'hidden' : ''}`}>
          <Link to="/">Home</Link>
          <span className="separator">|</span>
          <Link to="/about">About Us</Link>
          <span className="separator">|</span>

          <div className="dropdown">
            <Link className="dropbtn">Therapies</Link>
            <div className="dropdown-content">
              <div
                onClick={() =>
                  handleDropdownClickForTherapies('/therapies', 'ozone')
                }
              >
                Ozone Therapy
              </div>
              <div
                onClick={() =>
                  handleDropdownClickForTherapies('/therapies', 'detox')
                }
              >
                Detoxification Therapy
              </div>
              <div
                onClick={() =>
                  handleDropdownClickForTherapies('/therapies', 'rejuvenation')
                }
              >
                Rejuvenation Therapy
              </div>
              <div
                onClick={() =>
                  handleDropdownClickForTherapies('/therapies', 'cupping')
                }
              >
                Cupping Therapy
              </div>
              <div
                onClick={() =>
                  handleDropdownClickForTherapies('/therapies', 'acupuncture')
                }
              >
                Acupuncture Therapy
              </div>
            </div>
          </div>

          <span className="separator">|</span>
          <Link to="/consultation">Consultation</Link>
          <span className="separator">|</span>

          <div className="dropdown">
            <Link className="dropbtn">Gallery</Link>
            <div className="dropdown-content">
              <div onClick={() => handleDropdownClick('/Our-Clinic')}>
                Our Clinic
              </div>
              <div onClick={() => handleDropdownClick('/Indian-Coast-Guard')}>
                Indian Coast Guard
              </div>
              <div onClick={() => handleDropdownClick('/ONGC')}>ONGC</div>
              <div onClick={() => handleDropdownClick('/GAIL')}>GAIL</div>
              <div onClick={() => handleDropdownClick('/PANJANCO')}>Panjanco</div>
            </div>
          </div>

          <span className="separator">|</span>

          <div className="dropdown">
            <Link className="dropbtn">Article</Link>
            <div className="dropdown-content">
              <div onClick={() => handleDropdownClick('/research-details')}>Research</div> 
              <div onClick={() => handleDropdownClick('/articles')}>News</div>
            </div>
          </div>

          <span className="separator">|</span>
          <Link to="/contactUs">Contact</Link>
        </div>

        <div
          className={`hamburger ${isMenuOpen ? 'open' : ''}`}
          onClick={handleMenuToggle}
        >
          {isMenuOpen ? (
            <div className="close-icon">✕</div>
          ) : (
            <div className="menu-icon">☰</div>
          )}
        </div>
      </div>

      {isMenuOpen && (
        <div className="menu-content">
          <Link to="/" onClick={handleMenuToggle}>
            Home
          </Link>
          <Link to="/about" onClick={handleMenuToggle}>
            About Us
          </Link>

          <div className="mobile-dropdown">
            <div
              className="mobile-dropbtn"
              onClick={() => handleDropdownToggle('therapies')}
            >
              <div className='mobile-dropbtn-content'>
                <span className='dropbtn-content'>Therapies</span>
                <span className='dropbtn-content'>{activeDropdown === 'therapies' ? '−' : '+'}</span>
              </div>
            </div>
            {activeDropdown === 'therapies' && (
              <div className="dropdown-content show">
                <div
                  onClick={() =>
                    handleDropdownClickForTherapies('/therapies', 'ozone')
                  }
                >
                  Ozone Therapy
                </div>
                <div
                  onClick={() =>
                    handleDropdownClickForTherapies('/therapies', 'detox')
                  }
                >
                  Detoxification Therapy
                </div>
                <div
                  onClick={() =>
                    handleDropdownClickForTherapies(
                      '/therapies',
                      'rejuvenation'
                    )
                  }
                >
                  Rejuvenation Therapy
                </div>
                <div
                  onClick={() =>
                    handleDropdownClickForTherapies('/therapies', 'cupping')
                  }
                >
                  Cupping Therapy
                </div>
                <div
                  onClick={() =>
                    handleDropdownClickForTherapies('/therapies', 'acupuncture')
                  }
                >
                  Acupuncture Therapy
                </div>
              </div>
            )}
          </div>

          <div className="mobile-dropdown">
            <Link to="/consultation" onClick={handleMenuToggle}>
              Consultation
            </Link>
          </div>

          <div className="mobile-dropdown">
            <div
              className="mobile-dropbtn"
              onClick={() => handleDropdownToggle('gallery')}
            >
                  <div className='mobile-dropbtn-content'> 
                    <span className='dropbtn-content'>Gallery</span>
                    <span className='dropbtn-content'>{activeDropdown === 'gallery' ? '−' : '+'}</span>
                  </div>
            </div>
            {activeDropdown === 'gallery' && (
              <div className="dropdown-content show">
                <div onClick={() => handleDropdownClick('/Our-Clinic')}>
                  Our Clinic
                </div>
                <div
                  onClick={() => handleDropdownClick('/Indian-Coast-Guard')}
                >
                  Indian Coast Guard
                </div>
                <div onClick={() => handleDropdownClick('/ONGC')}>ONGC</div>
                <div onClick={() => handleDropdownClick('/GAIL')}>GAIL</div>
                <div onClick={() => handleDropdownClick('/PANJANCO')}>Panjanco</div>
              </div>
            )}
          </div>

          <div className="mobile-dropdown">
            <div
              className="mobile-dropbtn"
              onClick={() => handleDropdownToggle('article')}
            >
              <div className="mobile-dropbtn-content">
                  <span className="dropbtn-content">Article</span>
                  <span className="dropbtn-content">{activeDropdown === 'article' ? '−' : '+'}</span>
              </div>
            </div>
            {activeDropdown === 'article' && (
              <div className="dropdown-content show">
                <div onClick={() => handleDropdownClick('/research-details')}>Research</div>
                <div onClick={() => handleDropdownClick('/articles')}>News</div>
                {/* <div onClick={() => handleDropdownClick('/article')}>Research</div> */}
              </div>
            )}
          </div>

          <Link to="/contactUs" onClick={handleMenuToggle}>
            Contact
          </Link>
        </div>
      )}
    </nav>
  );
};

export default NewNavbar;