import React from "react";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/autoplay"; 
import "./ArticleSection.css";

import { Autoplay, Pagination, Navigation } from "swiper/modules"; 
import VideoSection from "../VideoSection/VideoSection";

const researchArticles = [
  {
    id: 1,
    title: "news article 1",
    description: "Effect of Yoga on metabolic disorders",
    imageUrl: "https://i.ibb.co/kMv3Gt7/yoga-4489430-640.jpg",
  },
  {
    id: 2,
    title: "yoga",
    description: "Effect of Yoga on metabolic disorders",
    imageUrl: "https://i.ibb.co/kMv3Gt7/yoga-4489430-640.jpg",
  },
  {
    id: 3,
    title: "children",
    description: "Children on obesity and ADHD",
    imageUrl: "https://i.ibb.co/PcTcv48/boy-8235025-640.jpg",
  },
  {
    id: 4,
    title: "news article 4",
    description: "Role of Diet and impact of Biochemical changes on body",
    imageUrl: "https://i.ibb.co/PcTcv48/boy-8235025-640.jpg",
  },
];

const newsArticles = [
  {
    id: 1,
    title: "news article 1",
    imageUrl: "https://i.ibb.co/2PtqF2C/Whats-App-Image-2024-09-19-at-18-11-29.jpg",
  },
  {
    id: 2,
    title: "How to Become the Boss of Your Emotions",
    description: "news article 2",
    imageUrl: "https://i.ibb.co/S6wyKXk/Whats-App-Image-2024-09-19-at-18-12-05.jpg",
  },
  {
    id: 3,
    title: "news article 3",
    imageUrl: "https://i.ibb.co/Wypz3gM/0a6e5098-2039-4a5d-81bd-8dc6b5409174.jpg",
  },
  {
    id: 4,
    title: "How to Become the Boss of Your Emotions",
    description: "news article 2",
    imageUrl: "https://i.ibb.co/WcFt2m1/1c889a08-292b-464c-bbf0-60a3dc24a2c8.jpg",
  },
];

const ArticleSection = () => {
  const navigate = useNavigate();

  const handleArticleClick = (path) => {
    navigate(path); // Navigate to the respective article detail page
  };

  return (
    <div className="article-section">
      {/* <div className="carousel-section carousel-research">
        <h2>Research Articles</h2>
        <div className="container-fluid mt-4">
          <div className="row ">
            <div className="col-12 col-sm-4 col-md-4  col-lg-4 ">
              <img
                className="img-fluid mt-3 ms-3 "
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTFOdfh6Q9iI2DGVcaVa-gPLqL_shV6znodqg&s"
              />
            </div>
            <div className="col-12 col-sm-8 col-md-8 col-lg-8  ">
              <p className="mt-3">Research Advisor</p>
              <h3> Dr. A. Alwin Robert </h3>
              <p>
                Its our privilege and honor to have Dr. A. Alwin Robert as our
                Research Advisor, working with Prince Sultan Military Medical
                City in the Department of Endocrinology and Diabetes Department,
                Ministry of Defense, Riyadh, Kingdom of Saudi Arabia. He is a
                Member and Reviewer of various International Journals and
                published XXXX Numbers of Research papers on Diabetes Meletus in
                Saudi Arabia.
              </p>
              <button className="btn btn-info">READ MORE</button>
            </div>
          </div>
        </div>
      </div> */}
      <div className="carousel-section carousel-news">
        <h2>News Articles</h2>
        <Swiper
          modules={[Autoplay, Pagination, Navigation]}
          spaceBetween={20}
          slidesPerView={2}
          loop={true}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          }}
          pagination={{ clickable: true }}
          navigation={true}
          breakpoints={{
            320: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 1,
            },
            1024: {
              slidesPerView: 2,
            },
          }}
        >
          {newsArticles.map((article, index) => (
            <SwiperSlide key={index}>
              <div
                className="carousel-item carousel-item-news"
                onClick={() => handleArticleClick(`/articles`)} ///articles
              >
                <img src={article.imageUrl} alt={article.title} />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <VideoSection />
    </div>
  );
};

export default ArticleSection;
