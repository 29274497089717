import React from 'react';
import { Link } from 'react-router-dom';
import './GalleryPageNav.css';

const sections = [
    { name: 'Our Clinic', 
      count: 12, 
      path: '/Our-Clinic', 
      img: 'https://i.ibb.co/G7MgjCJ/clinic-1.jpg',
      logo: 'https://i.ibb.co/n3MPJjs/dm-logo.png'
    },
    { name: 'Indian Coast Guard', 
      count: 15, 
      path: '/Indian-Coast-Guard', 
      img: 'https://i.ibb.co/yspyrLj/c-1.jpg',
      logo: 'https://i.ibb.co/7tsGfv1/indiancoastguard.png'
    },
    { name: 'ONGC', 
      count: 10, 
      path: '/ONGC', 
      img: 'https://i.ibb.co/DWN4jDj/ongc-6.jpg',
      logo: 'https://i.ibb.co/0VSZkPv/ongc.png'
    },
    { name: 'GAIL', 
      count: 8, 
      path: '/GAIL', 
      img: 'https://i.ibb.co/0VStcy3/gail-1.jpg',
      logo: 'https://i.ibb.co/JmVnnmB/gail-logo.png'
    },
    { name: 'Panjanco', 
      count: 5, 
      path: '/PANJANCO', 
      img: 'https://i.ibb.co/Y8jzX2j/pa-2.jpg',
      logo: 'https://i.ibb.co/RNjy4H3/panjanco.jpg'
    },
    { name: "Women Polytechnic College", 
      count: 5 , 
      path: "/Women-Polytechnic-College", 
      img: "https://i.ibb.co/Kx830Kf/p-1.jpg",
      logo: 'https://i.ibb.co/mFSZswK/polytechnic.jpg'
    }
  ];

const GalleryPageNav = () => {
    return (
        <div className="gallery-container-nav">
          <div className="gallery-grid-nav">
            <div className="gallery-title-nav">
              <img src="https://i.ibb.co/Lk3qHD9/gallery-icon.png" alt="gallery"/>
              <h2>Our's Gallery</h2>
            </div>
            {sections.map((section, index) => (
              <Link
                to={section.path}
                key={section.name}
                className={`gallery-section-nav gallery-section-nav-${index + 1}`}
              >
                <div className="gallery-image-wrapper-nav">
                  <img src={section.img} alt={section.name} className="gallery-image-nav" />
                  <div className="gallery-overlay-nav">
                    <div className="gallery-info-nav">
                      <div className="gallery-info-content-nav">
                        <h2>{section.name}</h2>
                        <p>{section.count} Photos</p>
                      </div>
                      <div className='gallery-info-logo-nav'>
                        <img className={`logo-${index + 1}`} src={section.logo} alt={`logo-${index + 1}`} />
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      );
}

export default GalleryPageNav;