import React from "react";
import "./LogoCarousel.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { useNavigate } from 'react-router-dom';

const logos = [
  { id: 1, src: "https://i.ibb.co/dJ0SqKh/nhra-bahrain.png", link: "logo1" },
  { id: 2, src: "https://i.ibb.co/g67f2sX/ccryn-1.jpg", link: "logo2" },
  { id: 3, src: "https://i.ibb.co/QbGf3rJ/Tamil-Nadu-Logo-svg.png", link: "logo3" },
  { id: 4, src: "https://i.ibb.co/MscFnzC/nrb.jpg", link: "logo4" },
  { id: 5, src: "/homepage/msme3.jpeg", link: "logo5"},
];

const LogoCarousel = () => {
  const navigate = useNavigate();

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 5000,
    autoplaySpeed: 0,
    cssEase: "linear",
    pauseOnHover: true,
  };

  const handleLogoClick = (link) => {
    navigate(`/details/${link}`);
  };

  return (
    <div className="logo-container">
      <div className="logo-header">
        <h2>Our Licenses</h2>
      </div>
      <div className="slider-container">
        <Slider {...settings}>
          {logos.map((logo) => (
            <div className="logo-card" key={logo.id}>
              <div
                className="logo-wrapper"
                onClick={() => handleLogoClick(logo.link)}
              >
                <img src={logo.src} alt={`Logo ${logo.id}`} />
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default LogoCarousel;
