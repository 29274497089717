import React from 'react';
import './FloatingOnlineConsultation.css';
import { Link } from 'react-router-dom';

const FloatingOnlineConsultation = () => {
  return (
    <Link to="/online-consultation" className="floating-consultation">
      <i className="stethoscope-icon"></i>
      <span>Online Consultation</span>
    </Link>
  );
}

export default FloatingOnlineConsultation;
