import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';

const logoImages = [
    {
      src: "https://i.ibb.co/4K1ZGNX/ayush-membership-page-0001.jpg",
      title: "Ayush",
      description: "visit - www.ayush.gov.in",
      link:"https://ayush.gov.in/",
    },
    {
      src: "https://i.ibb.co/vZwjVHL/iya-member-page-0001.jpg",
      title: "Indian Yoga Association",
      description: "visit - www.indian yoga association.in",
      link:"https://www.yogaiya.in/"
    },
    {
      src: "https://i.ibb.co/LRbBfV8/inygma-member-page-0001.jpg",
      title: "INYGMA",
      description: "visit - www.inygma.org.in",
      link:"https://inygma.org.in/"
    },
    {
        src: "https://i.ibb.co/7X9crbZ/Ozore-forum-of-India-page-0001.jpg",
        title: "Ozone",
        description: "visit - www.ozoneforumofindia.com",
        link:"https://www.ozoneforumofindia.com/"
      },
  ];
  
  const logoDetailsData = {
    logo11: {
      title: "Logo 1 Title",
      description: "Description for Logo 1",
      index: 0,
    },
    logo22: {
      title: "Logo 2 Title",
      description: "Description for Logo 2",
      index: 1,
    },
    logo33: {
      title: "Logo 3 Title",
      description: "Description for Logo 3",
      index: 2,
    },
    logo44: {
        title: "Logo 4 Title",
        description: "Description for Logo 4",
        index: 3,
      },
  };
  
  const MemberDetails = () => {
    const { logoId } = useParams();
    const logoInfo = logoDetailsData[logoId];
    const { title, description, index } = logoInfo;
  
    const [highlightedIndex, setHighlightedIndex] = useState(index);
    const imageRefs = useRef([]);
  
    useEffect(() => {
      if (highlightedIndex !== null && imageRefs.current[highlightedIndex]) {
        const timer = setTimeout(() => {
          const highlightedImage = imageRefs.current[highlightedIndex];
          const offset = 100; 
          const elementPosition = highlightedImage.getBoundingClientRect().top + window.scrollY;
          const offsetPosition = elementPosition - offset;
  
          window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth',
          });
        }, 500);
        return () => clearTimeout(timer);
      }
    }, [highlightedIndex]);
  
    return (
      <div className="logo-details-container" key={logoId}>
        <div className="logo-header">
        </div>
        <div className="logo-image-section">
          {logoImages.map((img, idx) => (
            <div
              key={`${logoId}-${idx}`}
              ref={(el) => (imageRefs.current[idx] = el)}
              className={`logo-image ${idx === highlightedIndex ? 'highlight' : ''}`}
            >
              <h3>{img.title}</h3>
              <Zoom
                zoomImg={{ className: 'zoomImg' }}
                zoomMargin={20}
                overlayBgColorEnd="rgba(0, 0, 0, 0.9)"
              >
                <img src={img.src} alt={`Logo Image ${idx + 1}`} />
              </Zoom>
              <a href={img.link} target="_blank">{img.description}</a>
            </div>
          ))}
        </div>
      </div>
    );
  };

  export default MemberDetails;