import React, { useEffect, useRef } from 'react';
import './ContactUs.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faTwitter, faInstagram, faLinkedin , faYoutube} from '@fortawesome/free-brands-svg-icons';
import L from 'leaflet';

const ContactUs = () => {
  const mapRef = useRef(null);

  useEffect(() => {
    if (!mapRef.current) {//10.925957, 79.821088
      mapRef.current = L.map('map').setView([10.925957, 79.821088], 15);

      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      }).addTo(mapRef.current);

      const marker = L.marker([10.925957, 79.821088]).addTo(mapRef.current);
      marker.bindPopup("<b>DM Nature Cure & Wellness Centre</b>").openPopup();

      marker.on('click', () => {
        window.open("https://maps.app.goo.gl/AkKB1nF8dcyEV9yJ7", '_blank');
      });
    }

    return () => {
      if (mapRef.current) {
        mapRef.current.remove();
        mapRef.current = null;
      }
    };
  }, []);

  return (
    <div className="contact-us">
      <div className="contact-header">
        <h2>Get in Touch with us for more information</h2>
        {/* <img src="https://i.ibb.co/1fCZ15r/contact-us-communication-support-service-assistance-concept-1-11zon.jpg" alt="Contact Us" /> */}
        <div className="contact-image-container"></div>
      </div>
      <div className="contact-cards">
        <div className="contact-card">
          <FontAwesomeIcon icon={faPhone} className="contact-icon" />
          <h3>Phone Number</h3>
          <p><a href="tel:+918680888963">+91 86808 88963</a></p>
        </div>
        <div className="contact-card">
          <FontAwesomeIcon icon={faEnvelope} className="contact-icon" />
          <h3>Email</h3>
          <p><a href="mailto:care@dmnaturecure.com">care@dmnaturecure.com</a></p>
        </div>
        <div className="contact-card">
          <h3>Social Links</h3>
          <div className="social-icons-contact">
            <a href="https://www.facebook.com/profile.php?id=61559933699361" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faFacebook} className="social-icon-contact" />
            </a>
            <a href="https://x.com/dmnaturecure?t=z9CJKKnUP3HyissUbPoNyg&s=09" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faTwitter} className="social-icon-contact" />
            </a>
            <a href="https://www.instagram.com/_dhanalakshmi_mathan/" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faInstagram} className="social-icon-contact" />
            </a>
            <a href="https://www.linkedin.com/in/dhana-lakshmi-477910128/" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faLinkedin} className="social-icon-contact" />
            </a>
            <a href="https://www.youtube.com/@Dhanalakshmi-zq9zn" target='_blank' rel='noopener noreferrer'>
              <FontAwesomeIcon icon={faYoutube} className="social-icon-contact" />
            </a>
          </div>
        </div>
      </div>
      <div className="address-map-card">
        <div className="address-contact-container">
          <div className="address-contact">
            <h3>Our Clinic</h3>
            <p>No. 23, LAKSHMI KALYAN NAGAR,</p>
            <p>PATCHUR, THIRUNALLAR ROAD,</p>
            <p>KARAIKKAL – 609 602,</p> 
            <p>PUDUCHERRY, INDIA</p>
            <p><a href="tel:+918680888963">+91 86808 88963</a></p>
          </div>
        </div>
        <div className="map-contact" id="map"></div>
      </div>
    </div>
  );
};

export default ContactUs;
