import React from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';
import "./MemberSlider.css";

const logos = [
  { id: 1, src: 'https://i.ibb.co/r7kYVLZ/ayush.jpg', link: '/detail/logo11' },
  { id: 2, src: 'https://i.ibb.co/2ZS7HV5/inygma-1.jpg', link: '/detail/logo22' },
  { id: 3, src: 'https://i.ibb.co/6BzG4hV/yogaiya-1.jpg', link: '/detail/logo33' },
  { id: 4, src: 'https://i.ibb.co/LrL5WjF/ozone-1.jpg', link: '/detail/logo44' },
  // { id: 5, src: 'https://i.ibb.co/nzc6X1n/tree.jpg', link: '/details/logo5' },
  // { id: 6, src: 'https://i.ibb.co/nzc6X1n/tree.jpg', link: '/details/logo6' },
];

const MemberSlider = () => {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 5000,
    autoplaySpeed: 1000,
    cssEase: "linear",
    pauseOnHover: true
  };

  const handleLogoClick = (link) => {
    window.location.href = link;
  };

  return (
    <div className="member-container">
      <div className="member-header">
        <h2>Our Membership</h2>
      </div>
      <div className="slider-container">
        <Slider {...settings}>
          {logos.map((logo) => (
            <div
              key={logo.id}
              className="logo-wrapper"
              onClick={() => handleLogoClick(logo.link)}
            >
              <img src={logo.src} alt={`Logo ${logo.id}`} />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default MemberSlider;