import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLeaf, faBalanceScale, faHeartbeat, faShieldAlt, faRedo, faHandHoldingHeart } from '@fortawesome/free-solid-svg-icons';
import "./BenefitsOfNaturo.css";
import { useInView } from 'react-intersection-observer';

const BenefitsOfNaturo = () => {

    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.2,
      });

  return (
    <div ref={ref} className={`benefits-container ${inView ? 'visible' : ''}`}>
      <div className="bimage-container">
        <img src="/homepage/benefits-of-naturo.png" alt="image" />
      </div>
      <div className="bcontent-container">
        <div className="btext-container">
          <h1>Benefits of Naturopathy</h1>
          <p>
            The main benefit of Naturopathy is that it cures the underlying
            cause behind any health issue and cures the problem completely.
          </p>
          <p>The main benefits of Naturopathy include:</p>
          <ul>
            <li><FontAwesomeIcon icon={faLeaf} /> Boosting immunity in a natural way</li>
            <li><FontAwesomeIcon icon={faBalanceScale} /> Reduces excess weight</li>
            <li><FontAwesomeIcon icon={faHeartbeat} /> Stress reduction</li>
            <li><FontAwesomeIcon icon={faShieldAlt} /> Maintains emotional and physical balance</li>
            <li><FontAwesomeIcon icon={faShieldAlt} /> Prevents diseases</li>
            <li><FontAwesomeIcon icon={faRedo} /> Rejuvenates health</li>
          </ul>
        </div>
        <div className="btable-container">
          <h2>The treatment & therapy corresponds to</h2>
          <table>
            <thead>
              <tr>
                <th>Nature</th>
                <th>Therapy</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Earth</td>
                <td>Mud treatments</td>
              </tr>
              <tr>
                <td>Water</td>
                <td>Widely used as hydrotherapy (internal & external)</td>
              </tr>
              <tr>
                <td>Air</td>
                <td>Breathing Techniques/Acupuncture</td>
              </tr>
              <tr>
                <td>Fire</td>
                <td>Heat modalities</td>
              </tr>
              <tr>
                <td>Ether</td>
                <td>Diet/Fasting</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default BenefitsOfNaturo;
