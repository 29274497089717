const Therapies = [
  {
    id: "ozone",
    title: "Ozone Therapy ",
    subtitle:" A Revolutionary Approach to Wellness",
    titlesidebar:"Ozone Therapy",
    description: [
      
      "Ozone therapy is a safe, painless, and non-invasive treatment that harnesses the power of ozone gas to rejuvenate and revitalize the body. By administering a specific amount of ozone through various methods, individuals can experience:"
    ],
    points: [
      "Increased energy levels and cellular rejuvenation",
      "Enhanced anti-aging benefits through improved cellular metabolism",
      "Improved tissue elasticity and collagen production",
      "Relaxed and toned blood vessels, capillaries, and improved blood circulation",
      "Enhanced removal of harmful free radicals and toxins",
      "Improved nutrient uptake by healthy cells",
      
    ],
    additionalDescription: [
      "Ozone therapy stimulates the body's metabolic processes, leading to a renewed sense of energy, vitality, and youthfulness, empowering individuals to live life to the fullest.",
        
    ],
    points1:[
       "Increased glucose uptake and oxygenation by red blood cells",
      "Boosted energy levels and cellular vitality",
      "Enhanced overall wellbeing and reduced risk of ailments",
    ],
    imageUrl: "/therapies/Ozone-therapy.jpg"
  },
  {
    id: "detox",
    title: "Detoxification ",
    subtitle:" The Foundation of Healing",
    titlesidebar:"Detoxification",
    description: [
      "In today's world, we're constantly exposed to toxins through external factors like urban pollution, climate change, and chemical exposure, as well as internal elements like artificial food additives, preservatives, and packaging. These toxins can enter our bodies through digestion, respiration, or other means, affecting blood quality and disrupting its delicate alkaline pH balance. Poor lifestyle choices, irregular eating habits, and sleep disorders can further compromise this balance."
    ],
    
    additionalDescription: [
      "To address this, we employ various natural therapeutic modalities for effective detoxification. Detoxification is a crucial aspect of our treatment approach, aimed at eliminating harmful impurities and toxins that often underlie various illnesses. By removing these toxins, we lay the groundwork for optimal health and wellness."
    ],
    imageUrl: "/therapies/detox.jpg"
  },
  {
    id: "rejuvenation",
    title: "Rejuvenation Therapy",
    subtitle:"  Unlocking the Body's Natural Healing Potentia",
    titlesidebar:"Rejuvenation Therapy",
    description: [
      "Rejuvenation Therapy is a holistic approach that harnesses the body's innate healing abilities through non-conventional methods. This comprehensive therapy combines:"
    ],
    points: [
      "Personalized nutrition plans",
      "Herbal medicine",
      "Physical therapies"
    ],
    additionalDescription: [
      "to stimulate the body's self-healing processes. By doing so, this therapy:",
    ],
    points1:[
      " Removes toxins and promotes detoxification",
      " Boosts the immune system",
      " Restores normal bodily functions",
      " Prevents disease and organ damage",
    ],
    additionalDescription1: [
      "Ultimately, Rejuvenation Therapy enhances overall quality of life, empowering individuals to achieve optimal wellness and vitality."
    ],
    imageUrl: "/therapies/rejinuvation.jpg"
  },
  {
    id: "cupping",
    title: "Cupping Therapy",
    subtitle:" Enhancing Health and Wellbeing",
    titlesidebar:"Cupping Therapy",
    description: [
      "Cupping therapy utilizes suction pumps to create high negative pressure on the skin, resulting in:"
    ],
    points: [
      "Increased blood volume",
      "Enhanced capillary filtration rate",
      "Expulsion of filtered matter from interstitial spaces"
    ],
    additionalDescription: [
      "In wet cupping, this filtered matter, containing disease-causing substances like prostaglandins and inflammatory mediators, is removed, promoting therapeutic effects. Dry cupping, applied to specific points, improves blood circulation and facilitates toxin removal, enhancing overall wellbeing.",
      "As a complementary therapy, cupping supports health and wellness by:",
    ],
    points1:[
      " Promoting blood flow and circulation",
      " Removing toxins and inflammatory mediators",
      " Enhancing natural healing processes",
    ],
    additionalDescription1: [ 
      "By incorporating cupping into your wellness routine, you can experience improved overall health and wellbeing."
    ],
    imageUrl: "/therapies/cub-therapy.jpg"
  },
  {
    id: "acupuncture",
    title: "Acupuncture ",
    subtitle:"Restoring Balance and Promoting Overall Wellness",
    titlesidebar:"Acupuncture",
    description: [
      "Acupuncture is a holistic therapy that corrects energy imbalances in the body, effectively treating both acute and chronic diseases. By stimulating specific points, acupuncture:"
    ],
    points: [
      "Activates nerves, muscles, and connective tissues",
      "Enhances blood circulation",
      "Triggers the production of neurotransmitters like dopamine, serotonin, and oxytocin"
    ],
    additionalDescription: [
      "These effects help alleviate stress and pain, while also boosting the immune system to maintain overall health and wellbeing. By restoring balance to the body's energy, acupuncture promotes:",
    ],
    points1:[
      " Relief from chronic conditions",
      " Enhanced immune function",
      " Improved mental clarity and focus",
      " Increased overall vitality",
    ],
    additionalDescription1: [ 
       "Regular acupuncture sessions can lead to a better quality of life, empowering individuals to achieve optimal health and wellness."
    ],
    imageUrl: "/therapies/accupunture.jpg"
  },
  {
    id: "fasting",
    title: "Therapeutic Fasting ",
    subtitle:"Unlocking the Body's Natural Healing Potential",
    titlesidebar:"Therapeutic Fasting",
    description: [
      "Therapeutic fasting is a superior method of healing for various ailments, and our program is tailored to individual needs. By providing specific juices and soups based on disease conditions, we support the body's natural healing processes. Therapeutic fasting:"
    ],
    points: [
      "Gives the digestive system a much-needed rest",
      "Enhances the body's innate healing power",
      "Eliminates toxins and promotes overall detoxification"
    ],
    additionalDescription: [
      "Our program provides balanced nutrition that:",
    ],
    points1:[
      " Supports healing and enhances wellbeing",
      " Prevents further health issues",
    ],
    additionalDescription1: [ 
      "We firmly believe in the ancient wisdom: 'Let food be thy medicine and medicine be thy food.' By harnessing the power of therapeutic fasting, we empower individuals to achieve optimal health and wellness."
   ],
    imageUrl: "/therapies/Therapeutic Fasting.jpg"
  },
  {
    id: "diet",
    title: "Diet Therapy",
    subtitle:" Harnessing the Power of Food",
    titlesidebar:"Diet Therapy",
    description: [
      "As the ancient Greek physician Hippocrates said, 'Let food be thy medicine and medicine be thy food.' A well-balanced diet is essential for maintaining optimal health and boosting immunity. Consuming unnatural or toxic foods can have adverse effects on the body.",
      "To achieve optimal health and healing through diet and nutrition, focus on:"
    ],
    points: [
      "Eating foods in their natural state, unprocessed and unrefined",
      "Avoiding chemical additives and preservatives",
      "Incorporating organic, whole foods that are fresh and nutrient-dense"
    ],
    additionalDescription: [
      "By making these choices, you'll be nourishing your body with the best possible fuel for optimal functioning and well-being."
    ],
    imageUrl: "/therapies/diet.jpg"
  },
  {
    id: "mud",
    title: "Mud Therapy ",
    subtitle:"  Harnessing the Healing Power of Nature",
    titlesidebar:"Mud Therapy",
    description: [
      "Mud, one of the five elements of nature, has been revered for its profound impact on health and wellness. Rich in minerals and capable of retaining moisture and heat, mud possesses remarkable therapeutic properties. Our mud therapy applications include:"
    ],
    points: [
      "Mud packs: Targeted treatments for localized relief",
      "Face masks: Nourishing and rejuvenating the skin",
      "Mud baths: Immersive experiences for overall wellbeing"
    ],
    additionalDescription: [
      "Through these methods, we harness the natural healing power of mud to:",
    ],
    points1:[
      " Treat various health conditions",
      " Promote relaxation and stress relief",
      " Rejuvenate the body and mind",
      " Enhance overall wellbeing",
    ],
    additionalDescription1: [ 
       "Embrace the ancient wisdom of mud therapy and discover a deeper connection with nature's healing potential."
   ],
    imageUrl: "/therapies/mud-therapy.jpg"
  },
  {
    id: "therapeutic-yoga",
    title: "Therapeutic Yoga ",
    subtitle:"Balancing Body, Mind, and Spirit",
    titlesidebar:"Therapeutic Yoga",
    description: [
      "Our Therapeutic Yoga program addresses specific health issues and promotes overall wellbeing by tackling physical, mental, and emotional imbalances. Our expert instructors will guide you through:"
    ],
    points: [
      "Yoga asanas (postures) to improve flexibility and strength",
      "Pranayama (breathwork) to calm the mind and balance the nervous system",
      "Mudras (hand gestures) and bandhas (energy locks) to enhance energy flow",
      "Meditation techniques to quiet the mind and access inner peace"
    ],
    additionalDescription: [
      "We tailor our Therapeutic Yoga sessions to address individual health conditions, empowering you to:",
    ],
    points1:[
      " Manage chronic pain and inflammation",
      " Reduce stress and anxiety",
      " Improve sleep quality",
      " Enhance mental clarity and focus",
      " Boost immune function",
    ],
    additionalDescription1: [ 
      
      "By incorporating Therapeutic Yoga into your lifestyle, you'll experience a transformative journey towards holistic health and hassle-free living."
  ],
    imageUrl: "https://i.ibb.co/FbZvxR7/d8b2b5c9-9b0b-41ec-bced-81af59eff683.jpg"
  },
  {
    id: "underwater-massage",
    title: "Underwater Massage ",
    subtitle:"Harnessing Hydrotherapy for Deep Relaxation",
    titlesidebar:"Underwater Massage",
    description: [
      "Experience the bliss of underwater massage, a revolutionary therapy that leverages water pressure to melt away tension and stress. Performed in specialized hydrotherapy equipment, this innovative treatment offers a range of health benefits, including:"
    ],
    points: [
      "Pain relief: Soothe aching muscles and joints",
      "Improved circulation: Boost blood flow and oxygenation",
      "Muscle relaxation: Release deep-seated tension",
      "Stress reduction: Calm the mind and body",
      "Enhanced mobility: Increase flexibility and range of motion"
    ],
    additionalDescription: [
      "The buoyancy of water reduces gravity's impact, allowing for a gentle yet effective massage. As you float effortlessly, our skilled therapists will work out knots, ease muscle strain, and leave you feeling weightless and serene. Indulge in underwater massage and discover a new dimension of relaxation and wellness."
    ],
    imageUrl: "/therapies/underwater-therapy.jpg"
  },
  {
    id: "therapeutic-massage",
    title: "Therapeutic Massage ",
    subtitle:"Enhancing Health and Wellbeing",
    titlesidebar:"Therapeutic Massage",
    description: [
      "Therapeutic Massage is a targeted approach to addressing musculoskeletal issues and promoting overall health and wellbeing. By reducing stress and addressing underlying health concerns, this specialized massage technique:"
    ],
    points: [
      "Eases muscle tension and pain",
      "Improves range of motion and flexibility",
      "Enhances blood circulation and oxygenation",
      "Facilitates detoxification and lymphatic drainage",
      "Promotes relaxation and calms the mind"
    ],
    additionalDescription: [
      "Skilled therapists employ various techniques to manipulate soft tissues, including:",
    ],
    points1:[
      " Soft tissue mobilization",
      " Deep tissue massage",
      " Myofascial release",
      " Trigger point therapy",
      "By incorporating Therapeutic Massage into your wellness routine, you'll experience:",
      " Improved physical function and mobility",
      " Enhanced mental clarity and focus",
      " Reduced stress and anxiety",
      " Better overall health and wellbeing",
    ],
    additionalDescription1: [ 
      
    "Discover the transformative power of Therapeutic Massage and start your journey towards optimal health and wellness today!"
  ],
    imageUrl: "/therapies/Therapatic-Massage.jpg"
  },
  {
    id: "pain-management",
    title: "Pain Management",
    titlesidebar:"Pain Management",
    description: [
      "At our center, we employ a multidisciplinary approach to pain management, tailoring our methods to each individual's specific condition. Our primary focus is to identify the underlying cause and address it at its root. To achieve this, we utilize a range of therapies, including:"
    ],
    points: [
      "Ozone therapy to promote healing and reduce inflammation",
      "Nutritional therapy to optimize diet and nutrition",
      "Lifestyle modification to address habits and behaviors contributing to pain",
      "Mind-body therapy to manage stress and emotional factors",
      "Detoxification to eliminate toxins and promote overall wellness"
    ],
    additionalDescription: [
      "By addressing the root cause of pain and incorporating these therapies, we empower individuals to transform their experience of pain and reclaim their quality of life."
    ],
    imageUrl: "/therapies/pain-manage.jpg"
  },
  {
    id: "stress-management",
    title: "Stress Management",
    subtitle:"  Managing Stress for Healthy Living",
    titlesidebar:"Stress Management",
    description: [
      "Relieving oneself from stress and tension is essential for maintaining a healthy lifestyle. Deep relaxation techniques are a powerful tool for coping with day-to-day stress and promoting overall wellbeing. By incorporating these techniques into your daily routine, you can:"
    ],
    points: [
      "Reduce anxiety and feelings of overwhelm",
      "Improve mood and emotional resilience",
      "Enhance focus and mental clarity",
      "Boost immune function and physical health",
      "Increase self-awareness and self-care"
    ],
    additionalDescription: [
      "Deep relaxation techniques, such as progressive muscle relaxation, visualization, and mindfulness meditation, can help calm the mind and body, leading to a more balanced and fulfilling life."
    ],
    imageUrl: "/therapies/stress-management.jpg"
  },
  {
    id: "antiaging",
    title: "Anti-Aging ",
    subtitle:"The Impact of Inflammation on Aging",
    titlesidebar:"Anti-Aging",
    description: [
      "Chronic inflammation, triggered by stress, poor nutrition, chronic diseases, alcohol, and smoking, can severely damage elastin fibers, leading to loss of skin strength, firmness, and elasticity. This results in premature aging, characterized by loose, sagging skin and wrinkles."
    ],
    points: [
      "Reducing inflammation through dietary modifications and natural therapies",
      "Addressing nutritional imbalances to promote optimal health",
      "Enhancing functional efficiency by detoxifying internal systems",
      "Bringing radiance and vitality to the skin through external treatments"
    ],
    additionalDescription: [
      "By adopting a naturopathic approach, individuals can mitigate the effects of inflammation, rejuvenate their skin, and achieve a more youthful, vibrant appearance."
    ],
    imageUrl: "/therapies/Anti-Aging.jpg"
  },
  {
    id: "meditation",
    title: "Meditation ",
    subtitle:"Mindfulness for a Better Life",
    titlesidebar:"Meditation",
    description: [
      "Meditation, a key practice in yoga, is tailored to individual needs to reap numerous benefits. Most diseases stem from a hasty lifestyle, stressful situations at home and work, peer pressure, and more. These negative impacts can cause or exacerbate diseases. Meditation offers many benefits, including:"
    ],
    points: [
      "Relieving stress and tension",
      "Overcoming nervous weakness",
      "Enhancing memory and creative thinking",
      "Developing problem-solving skills",
      "Promoting emotional well-being"
    ],
    imageUrl: "/therapies/yoga.jpg"
  },

  {
    id: "lifestylemodification",
    title: "Lifestyle Modification",
    titlesidebar:"Lifestyle Modification",
    description: [
     "According to World Health Organization (WHO) research, Non-Communicable Diseases (NCDs) claim 41 million lives annually, accounting for nearly 74% of global deaths. The four primary lifestyle-related diseases are:"
    
    ],
    points: [
      "Cardiovascular Diseases (CVD)",
      "Cancer",
      "Chronic Respiratory Diseases",
      "Diabetes"
    ],
    additionalDescription: [
      "These conditions require immediate attention to save lives. While treatments range from medication to surgery, prevention is always better than cure. We understand modern world demands and needs, but small changes in daily habits and behaviors – such as diet, physical activity, stress management, and sleep – can significantly improve one's current state. We conduct detailed histories, perform case studies, and help individuals improve."
    ],
    imageUrl: "/therapies/lifestyleModification.jpg"
  }

];

export default Therapies;
