import React from 'react';
import './ResearchDetails.css';
const ResearchDetails = () => {
  return (
    <div>

<div className="advisor-container">
  <div className="advisor-row">
    <div className="advisor-image">
      <img
        className="img-responsive"
        src="/article/professor.jpeg"
        alt="Research Advisor"
      />
    </div>
    <div className="advisor-details">
      <h3 >Dr. A. Alwin Robert</h3>
      <p className="advisor-role">Research Advisor,</p>
      <p className='paradr'>
        We are honored to have Dr. A. Alwin Robert, a renowned expert in Endocrinology and Diabetes, as our Research Advisor. Dr. Robert is affiliated with Prince Sultan Military Medical City in Riyadh, Kingdom of Saudi Arabia, and is a member and reviewer of several international journals. He has published numerous research papers on Diabetes Mellitus in Saudi Arabia.
      </p>
    </div>
  </div>


  <div className="research-details">
      <h2>Our Research Focus Areas</h2>
      <ul>
        <li>Investigating the effects of Yoga on metabolic disorders, specifically Diabetes</li>
        <li>Examining the impact of Nature Cure Diet Therapy on biochemical changes in the body</li>
        <li>Studying the relationship between obesity, Diabetes, and natural cure approaches</li>
        <li>Analyzing the link between lifestyle disorders and the risk of Diabetes</li>
        <li>Exploring Ozone Therapy as a potential game-changer in treating viral infections</li>
      </ul>
    </div>


</div>

    </div>
    
  );
};

export default ResearchDetails;