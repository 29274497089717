import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from "./components/Homepage/LandingPage/LandingPage";
import About from "./components/About/About";
import NaturopathyTherapy from './components/NaturopathyTherapy/NaturopathyTherapy';
import TherapyOverview from './components/TherapyOverview/TherapyOverview';
import NewNavbar from "./components/Navbar/NewNavbar";
import './App.css';
import Footer from './components/Footer/Footer';
import '@fortawesome/fontawesome-free/css/all.min.css';
import FloatingWhatsAppIcon from './components/FloatingWhatsAppIcon/FloatingWhatsAppIcon';
import SectionPage from './components/SectionPage/SectionPage';
import Consultation from './components/Consultation/Consultaion';
import BookingForm from './components/BookingForm/BookingForm';
import LogoDetails from './components/LogoDetails/LogoDetails';
import FloatingOnlineConsultation from './components/FloatingOnlineConsultation/FloatingOnlineConsultation';
import ScrollToTop from './components/Utilities/ScrollToTop';
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy';
import CancellationRefundPolicy from './components/CancellationRefundPolicy/CancellationRefundPolicy';
import ContactUs from './components/ContactUs/ContactUs';
import GalleryPageNav from './components/GalleryPageNav/GalleryPageNav';
import ArticleListPage from './components/ArticleListPage/ArticleListPage';
import MemberDetails from './components/MemberDetails/MemberDetails';
import ResearchDetails from './components/Homepage/ResearchSection/ResearchDetails';
import ConsultationBookingForm from './components/BookingForm/ConsultationBookingForm';

function App() {
  const [showConsultationButton, setShowConsultationButton] = useState(true);
  const [menuOpen, setMenuOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);

  return (
    <div className={`App ${menuOpen ? 'menu-open' : ''}`}>
      <Router>
        <ScrollToTop />
        {!showModal && <NewNavbar setMenuOpen={setMenuOpen} />}
        <Routes>
          <Route path="/" element={<LandingPage setShowConsultationButton={setShowConsultationButton} setShowModal={setShowModal} showModal={showModal} />} />
          <Route path="/about" element={<About />} />
          <Route path="/therapy/:therapyId" element={<NaturopathyTherapy />} />
          <Route path="/therapies" element={<TherapyOverview />} />
          <Route path="/consultation" element={<Consultation />} />
          <Route path="/gallery" element={<GalleryPageNav />} />
          <Route path="/article" element={<ArticleListPage />}/>
          <Route path="/contactUs" element={<ContactUs />}/>
          <Route path="/:section" element={<SectionPage />} />
          {/* <Route path="/online-consultation" element={<BookingForm setShowConsultationButton={setShowConsultationButton}/>}/> */}
          <Route path="online-consultation" element={<ConsultationBookingForm setShowConsultationButton={setShowConsultationButton}/>}/>
          <Route path="/details/:logoId" element={<LogoDetails />} />
          <Route path="/detail/:logoId" element={<MemberDetails />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/cancellation-refund" element={<CancellationRefundPolicy />} />
          <Route path="/articles" element={<ArticleListPage />}/>
          <Route path="/research-details" element={<ResearchDetails />}/>
        </Routes>
        <Footer />
        {showConsultationButton && !menuOpen && <FloatingOnlineConsultation />}
        {!showModal && !menuOpen && <FloatingWhatsAppIcon />}
      </Router>
    </div>
  );
}

export default App;
