import React from 'react';
import { Link } from 'react-router-dom';
import './ResearchSection.css';

const ResearchSection = () => {
  return (
    <div className="research-container">
      <h1 className='research-title'>Research Articles </h1>
      <div className="research-row">
        <div className="research-image">
          <img
            className="img-fluid"
            src="/article/professor.jpeg"
            alt="Research Advisor"
          />
        </div>
        <div className="research-info">
          <h3>Dr. A. Alwin Robert</h3>
          <p className="role">Research Advisor,</p>
          <p className="research-para">
            We are honored to have Dr. A. Alwin Robert, a renowned expert in Endocrinology and Diabetes, as our Research Advisor. Dr. Robert is affiliated with Prince Sultan Military Medical City in Riyadh, Kingdom of Saudi Arabia, and is a member and reviewer of several international journals. He has published numerous research papers on Diabetes Mellitus in Saudi Arabia.
          </p>
          <div className="research-readmore">
            <Link to="/research-details">
              <button className="read-more">READ MORE</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResearchSection;

